// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("/opt/build/repo/src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-industries-c-store-js": () => import("/opt/build/repo/src/pages/industries/c-store.js" /* webpackChunkName: "component---src-pages-industries-c-store-js" */),
  "component---src-pages-industries-enterprise-js": () => import("/opt/build/repo/src/pages/industries/enterprise.js" /* webpackChunkName: "component---src-pages-industries-enterprise-js" */),
  "component---src-pages-industries-restaurant-js": () => import("/opt/build/repo/src/pages/industries/restaurant.js" /* webpackChunkName: "component---src-pages-industries-restaurant-js" */),
  "component---src-pages-industries-retail-js": () => import("/opt/build/repo/src/pages/industries/retail.js" /* webpackChunkName: "component---src-pages-industries-retail-js" */),
  "component---src-pages-legal-js": () => import("/opt/build/repo/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-platform-js": () => import("/opt/build/repo/src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

